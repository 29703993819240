export default {
 data() {
  return {};
 },
 methods: {
  getApiRequestQuickPeriod(val) {
   let today = new Date();
   let lastDate = new Date().setDate(today.getDate() - val);
   return (
    this.formatDateApiRequest(lastDate) + "-" + this.formatDateApiRequest(today)
   );
  },
  formatDateApiRequest(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let formated = String(year) + String(month) + String(day);
   return formated;
  },
  getAnsweredNumb(arr) {
   let filteredAnswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Répondus") {
     filteredAnswered = element.data;
    }
   }
   if (filteredAnswered.length > 0) {
    return filteredAnswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
  getAnsweredNumbWithPercentage(arr) {
   let filteredAnswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Répondus") {
     filteredAnswered = element.data.map(res =>
      res == 0 ? parseInt(res) : parseInt(res.split(".")[0])
     );
    }
   }
   if (filteredAnswered.length > 0) {
    return filteredAnswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
  getUnansweredNumb(arr) {
   let filteredUnanswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Non répondus") {
     filteredUnanswered = element.data;
    }
   }
   if (filteredUnanswered.length > 0) {
    return filteredUnanswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
  getUnansweredNumbWithPercentage(arr) {
   let filteredUnanswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Non répondus") {
     filteredUnanswered = element.data.map(res =>
      res == 0 ? parseInt(res) : parseInt(res.split(".")[0])
     );
    }
   }
   if (filteredUnanswered.length > 0) {
    return filteredUnanswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
 },
};
