<template>
 <div class="bg-gray-100 flex min_height_app">
  <div class="flex-1 flex flex-col overflow-hidden">
   <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
     <div v-if="requestErr.data">
      <Error404 />
     </div>

     <div v-else>
      <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
       <router-view :key="$route.fullPath" @setLoading="isLoading = $event" @error-404="requestErr = $event" />
      </section>
     </div>
    </main>
   </div>
  </div>
 </div>
 <Loading v-model:active="isLoading" :can-cancel="true" :is-full-page="true" loader="dots" />
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Error404 from "./components/Error404.vue";

const token = localStorage.getItem("token");

export default {
 title() {
  return `${localStorage.getItem("title")}`;
 },
 components: { Error404, Loading },
 data() {
  return {
   isLoading: false,
   requestErr: {},
   token,
  };
 },
 methods: {
  initIcon(icon) {
   var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
   link.type = "image/x-icon";
   link.rel = "shortcut icon";
   link.href = icon;
   document.getElementsByTagName("head")[0].appendChild(link);
  },
  openSideNavHover(event) {
   this.sideHoverNav = event;
   if (this.sideHoverNav === "sidebar.invoices" || this.sideHoverNav === "sidebar.tickets" || this.sideHoverNav === "Documents") {
    this.sideNavHover = false;
    this.showSideNav = true;
   } else {
    this.sideNavHover = true;
   }
   this.hideNoNav = true;
  },
  showSubmenuTest(event) {
   console.log(event);
  },
  async getApi() {
   try {
    axios.post("https://air-traffic.bluerocktel.net/api/flights").then((res) => {
     console.log(res.data[0]);
     let apiData = "";
     if (Object.keys(res.data[0]).length > 1) {
      apiData = res.data[0].encom_presentation;
      //apiData = res.data[0].cx_engine_presentation_labs;
      localStorage.setItem("isLocal", true);
     } else {
      apiData = res.data[0].default;
     }
     console.log(apiData);
     if (apiData && apiData.active) {
      this.initIcon(apiData.logo);
      localStorage.setItem("logo", apiData.logo);
      this.$store.dispatch("logo", apiData.logo);
      localStorage.setItem("title", apiData.title);
      if (!localStorage.getItem("language")) {
       localStorage.setItem("language", apiData.lang);
      }
      //   if (!this.$cookie.getCookie("API") || this.$cookie.getCookie("API") !== apiData.baseUrl) {
      //    this.$cookie.setCookie("API", apiData.baseUrl, {
      //     expire: "21d",
      //    });
      //   }
      this.$cookie.setCookie("API", 'https://xp-stats-201.bluerock.tel');
     }
    });
   } catch (error) {
    this.errorHandling(error);
   }
  },
 },
 mounted() {
  this.getApi();
 },
};
</script>

<style>
.side_nav_width {
 min-width: 270px;
 overflow: hidden;
}
.side_nav_off_width {
 width: 50px;
 overflow: hidden;
}
</style>
